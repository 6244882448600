import "./types";

const REACT_APP_UENI_WIDGET_URL = process.env.REACT_APP_UENI_WIDGET_URL;
const REACT_APP_UENI_WIDGET_CONTAINER_ID = process.env.REACT_APP_UENI_WIDGET_CONTAINER_ID;
const IFRAME_ID = "wg-iframe-auto";

function render(container_id: string, options?: any) {
  const container = document.getElementById(container_id);

  if (!container) return;

  const { iframe_width = "100%", iframe_height = "auto", page, ...searchParamsObject } = options ?? container.dataset;
  const searchParams = new URLSearchParams(searchParamsObject);
  const isAutoHeight = iframe_height === "auto";
  let pagePath = "";
  if (["sign-up"].includes(page)) {
    pagePath = `/${page}`;
  }

  const iframe = document.createElement("iframe");
  iframe.src = `${REACT_APP_UENI_WIDGET_URL}${pagePath}?${searchParams.toString()}`;
  iframe.width = iframe_width;
  iframe.height = iframe_height;
  if (isAutoHeight) {
    iframe.id = IFRAME_ID;
  }
  iframe.frameBorder = "0";
  container.replaceChildren(iframe);
}

(function init() {
  render(REACT_APP_UENI_WIDGET_CONTAINER_ID ?? "ueni-wg");
  window.addEventListener(
    "message",
    (ev) => {
      if (ev.origin === REACT_APP_UENI_WIDGET_URL && ev.data.type && ev.data.type === "resize-iframe") {
        const ifr = document.getElementById(IFRAME_ID);
        if (!ifr) return;
        ifr.style.height = ev.data.payload.height + "px";
      }
    },
    false
  );
})();

window.UENI_WG = {
  render,
};

export {};
